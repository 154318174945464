import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import PromotionLayout from "../../container/PromotionLayout"
import ImageShower from "../../components/WorkVisionComponents/ImageShower"


const GastbyLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  color: black;
  text-decoration: none;
`;

function NewsPage () {
  const { firstImage, secondImage } = useStaticQuery(graphql`
        query {
            firstImage: file(relativePath: { eq: "company_news_1.jpg" }) {
                childImageSharp {
                    fixed(height: 700) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            secondImage: file(relativePath: { eq: "company_news_2.jpg" }) {
                childImageSharp {
                    fixed(height: 700) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
  `)
  return (
    <PromotionLayout title="News" heightNone={true}>
      <ImageShower
        fixed={firstImage.childImageSharp.fixed}
        title="국토건설신문 기사 (2017.06.21)" >
        <GastbyLink to="http://www.mcnews.co.kr/61290">기사 바로가기</GastbyLink>
      </ImageShower>
      <ImageShower
        classes="last-shower"
        fixed={secondImage.childImageSharp.fixed}
        title="대한전문건설신문 기사 (2017.06.21)" >
        <GastbyLink to="https://www.koscaj.com/news/articleView.html?idxno=96498">기사 바로가기</GastbyLink>
      </ImageShower>
    </PromotionLayout>
  )
}

export default NewsPage;