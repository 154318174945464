import React, { useEffect, useState } from "react"
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from "gatsby-image"

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
`


const TextContainer = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${props => props.show ? 'transparent' : 'var(--third-color)'};
  border: 3px var(--third-color) solid;
  line-height: 46px;
  text-indent: 30px;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  transition: background-color .3s;
  
  :after {
    content: '▼';
    float: right;
    font-size: 20px;
    margin-right: 20px;
    text-indent: 0;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 15px;
    text-indent: 15px;
    :after {
      content: '▼';
      width: 20px;
      height: 44px;
      font-size: 20px;
      margin: 0;
    }
  }
`;

const Shower = styled.div`
  width: 100%;
  height: ${props => props.show ? '750px': '0'};
  overflow: hidden;
  transition: height .3s;
`;

const ImageContainer = styled.div`
  width: fit-content;
  margin: auto;
`;

const Image = styled(GatsbyImage)`  
  @media screen and (max-width: 768px) {
    width: 320px !important;
    
    & img {
      width: 320px !important;
      object-fit: contain !important;
    }
  }
`


function ImageShower ({children, fixed, classes, title}) {
  const [show, setShow] = useState(false);

  const handleClickText = () => setShow(value => !value)

  useEffect(() => {
    const changeShowFalse = () => setShow(false)

    document.addEventListener('click', changeShowFalse)
    return document.removeEventListener('click', changeShowFalse)
  }, [])

  return (
    <Container className={classes}>
      <TextContainer
        onClick={handleClickText}
        show={show}>
        {title}
      </TextContainer>
      <Shower show={show}>
        <ImageContainer>
          <Image fixed={fixed} />
        </ImageContainer>
        {children}
      </Shower>
    </Container>
  )
}

ImageShower.propsType = {
  children: PropTypes.node,
  title: PropTypes.string,
  classes: PropTypes.string
}

export default ImageShower;
